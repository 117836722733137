import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/index.js";
import AboutUs from "./Pages/AboutUs/index.js";
import AreaOfPractice from "./Pages/AreaOfPractice/index.js";
import "./App.css";
import ContactUs from "./Pages/ContactUs/index.js";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/aboutus" element={<AboutUs />} />
      <Route exact path="/areaofpractice/:link" element={<AreaOfPractice />} />
      <Route exact path="/contactus" element={<ContactUs />} />
    </Routes>
  );
}

export default App;
