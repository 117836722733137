import React from "react";
import "./index.css";
import Articles from "../Articles";
import { Link } from "react-router-dom";

const areaOfPractice = [
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639489840/civil_oye4ay.webp",
    heading: "Civil",
    link: "civil",
  },
  {
    image: "https://khannalawassociates.in/img/finger-print.svg",
    heading: "Criminal",
    link: "criminal",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639489841/cyber_l1in1q.webp",
    heading: "Cyber Law",
    link: "cyberlaw",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639489841/matirial_lufbep.webp",
    heading: "Matrimonial Laws Of Divorcee, Dvc, Maintenance , Dowry",
    link: "matrimoniallaws",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639489840/criminal_bimao4.webp",
    heading: "Human Rights",
    link: "humanrigits",
  },
];

const lawAssociates = [
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493538/ezgif-3-a11d51a2c439_lwgcax.webp",
    name: "Mr. Rajender Khanna",
    profession: "B.A LLB, LLM - PGD IPR, Cyber Law",
    location: "Hyderabad",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493544/ezgif-4-770267c53efa_mwivjw.webp",
    name: "Ms. Nasreen Banu",
    profession: "B.Com LLB",
    location: "Hyderabad",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493538/ezgif-3-a11d51a2c439_lwgcax.webp",
    name: "Mr. Yash Khanna",
    profession: "B.A LLB",
    location: "Hyderabad",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493538/ezgif-3-a11d51a2c439_lwgcax.webp",
    name: "Mr. Kiran Medagiri",
    profession: "BE LLB, LLM - PGD Human Rights",
    location: "Hyderabad",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493544/ezgif-4-770267c53efa_mwivjw.webp",
    name: "Ms. Sumalata",
    profession: "B.Com LLB",
    location: "Hyderabad",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493538/ezgif-3-a11d51a2c439_lwgcax.webp",
    name: "Mr. Manish Sharma",
    profession: "B.Com LLB",
    location: "Hyderabad",
  },
  {
    image:
      "https://res.cloudinary.com/dldekbym5/image/upload/v1639493538/ezgif-3-a11d51a2c439_lwgcax.webp",
    name: "Mr. P Devender",
    profession: "B.Com LLB",
    location: "Hyderabad",
  },
];

function Area() {
  return (
    <div>
      <div className="khanna-law-man-container">
        <div className="khanna-law-man-sub-container">
          <h1 className="khanna-law-welcome-container">
            Welcome To Syedan Law Associates
          </h1>
          <p className="khanna-law-ngo-container">
            Syedan Law Associates came into existence to cater knowledgeable,
            competent, and ethical legal services to those seeking professional
            counsel in various areas within the spectrum of law. The firm is the
            brainchild of veteran advocate, Rajendar Khanna (B.A LLB, LLM - PGD
            IPR, Cyber Law) and the President Guardians Human & Civil Rights
            Forum, an NGO registered under the auspices of the Government of
            Telengana.
          </p>
          <div className="khanna-law-button-conatiner">
            <button className="area-khanna-law-btn">Read More</button>
            <button
              className="area-khanna-law-btn"
              onClick={() =>
                document
                  .querySelector("#consultationContainer")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Legal consultation
            </button>
          </div>
        </div>
        <div className="khanna-law-image-conatiner">
          <img
            src="	https://res.cloudinary.com/dldekbym5/image/upload/v1639489841/waga2_nnhzid.webp"
            alt=""
            className="khanna-law-image-conatiner-image"
          />
        </div>
      </div>
      <h1 className="law-khanna-area1">Area of Practice</h1>
      <div className="law-khanna-area-container">
        {areaOfPractice.map((area, index) => (
          <Link
            to={`/areaofpractice/${area.link}`}
            key={index}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <div className="law-khanna-cards-container">
              <img className="law-khanna-area-images" src={area.image} alt="" />
              <h1 className="law-khanna-area-heading">{area.heading}</h1>
            </div>
          </Link>
        ))}
      </div>
      <div className="law-khanna-key-article-container">
        <h1 className="law-khanna-area1">
          Key peoples @ Syedan Law Associates
        </h1>
        <div className="law-associate-container">
          {lawAssociates.map((associate, index) => (
            <div className="law-khanna-peoples-container" key={index}>
              <img
                className="law-khanna-area1-images"
                src={associate.image}
                alt=""
              />
              <h5 className="home-page-lawAssociates-name">{associate.name}</h5>
              <p className="home-page-lawAssociates-profession">
                {associate.profession}
              </p>
              <p className="home-page-lawAssociates-location">
                {associate.location}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Articles />
    </div>
  );
}

export default Area;
