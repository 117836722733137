import React, { useState, useEffect } from "react";
import "./index.css";

import { BiTime } from "react-icons/bi";
import { FaNotesMedical, FaUserAlt, FaLanguage, FaPhone } from "react-icons/fa"; // Import additional icons

const iconComponents = {
  BiTime: BiTime,
  FaNotesMedical: FaNotesMedical,
  FaUserAlt: FaUserAlt,
  FaLanguage: FaLanguage,
  FaPhone: FaPhone,
};

const tabData = [
  {
    title: "Free Legal Consultation",
    content: [
      {
        key: "ONLY ON SATURDAY",
        key2: "STRICTLY WITH PRIOR APPOINTMENT",
        icon: "FaNotesMedical",
      },
      {
        key: "Consulting time",
        key2: "11:00 Hrs to 12:00 Hrs",
        icon: "BiTime",
      },
      {
        key: "Contact Person",
        key2: "Mr. Rajender Khanna",
        icon: "FaUserAlt",
      },
      {
        key: "Phone:",
        key2: "(+91) 966-661-0000",
        icon: "FaPhone",
      },
      {
        key: "Contact Person",
        key2: "Mrs. NASREEN BANU",
        icon: "FaUserAlt",
      },
      {
        key: "Phone:",
        key2: "+91 984-888-0884",
        icon: "FaPhone",
      },
    ],
  },
  {
    title: "Telephone Consultation",
    content: [
      {
        key: "MONDAY TO SUNDAY",
        key2: "SUBJECT TO AVAILABILITY",
        icon: "FaNotesMedical",
      },
      {
        key: "Consulting time",
        key2: "10.30 Hrs to 19.00 Hrs Only",
        icon: "BiTime",
      },
      {
        key: "ADVOCATE - 01",
        key2: "Mr. Rajender Khanna",
        icon: "FaUserAlt",
      },
      {
        key: "languages",
        key2: "TELUGU / ENGLISH / HINDI",
        icon: "FaLanguage",
      },
      {
        key: "Phone:",
        key2: "966-661-0000",
        icon: "FaPhone",
      },
      {
        key: "ADVOCATE - 02",
        key2: "Mrs. NASREEN BANU",
        icon: "FaUserAlt",
      },
      {
        key: "languages",
        key2: "TELUGU / ENGLISH / HINDI",
        icon: "FaLanguage",
      },
      {
        key: "Phone:",
        key2: "984-888-0884",
        icon: "FaPhone",
      },
    ],
  },
  {
    title: "Personal Consultation",
    content: [
      {
        key: "MONDAY TO FRIDAY",
        key2: "SUBJECT TO AVAILABILITY ONLY THROUGH APPOINTMENT",
        icon: "FaNotesMedical",
      },
      {
        key: "Consulting time",
        key2: "14.00 Hrs to 19.00 Hrs",
        icon: "BiTime",
      },
      {
        key: "Contact Person",
        key2: "Mrs. NASREEN BANU",
        icon: "FaUserAlt",
      },
      {
        key: "Phone:",
        key2: "(+91) 984-888-0884",
        icon: "FaPhone",
      },
      {
        key: "ADVOCATE",
        key2: "Mr. RAJENDER KHANNA",
        icon: "FaUserAlt",
      },
      {
        key: "languages",
        key2: "TELUGU / ENGLISH / HINDI",
        icon: "FaLanguage",
      },
      {
        key: "Phone:",
        key2: "966-661-0000",
        icon: "FaPhone",
      },
    ],
  },
  {
    title: "Free Legal Aid Consultation",
    content: [
      {
        key: "Only on SATURDAY",
        key2: "Only with Personal Appointments, No Telephonic Consultations.",
        icon: "FaNotesMedical",
      },
      {
        key: "Consulting time",
        key2: "11.00 To 12.00 Hrs",
        icon: "BiTime",
      },
      {
        key: "ADVOCATE",
        key2: "Mr. RAJENDER KHANNA",
        icon: "FaUserAlt",
      },
      {
        key: "languages",
        key2: "TELUGU / ENGLISH / HINDI",
        icon: "FaLanguage",
      },
      {
        key: "Phone:",
        key2: "(+91) 984-888-0884",
        icon: "FaPhone",
      },
    ],
  },
  {
    title: "Family Counselling Services",
    content: [
      {
        key: "MONDAY TO FRIDAY",
        key2: "Strictly with prior appointment",
        icon: "FaNotesMedical",
      },
      {
        key: "ADVOCATE & Counsellor",
        key2: "Mr. Kiran M",
        icon: "FaUserAlt",
      },
      {
        key: "languages",
        key2: "TELUGU / ENGLISH / HINDI",
        icon: "FaLanguage",
      },
      {
        key: "Phone:",
        key2: "(+91) 905-990-9969",
        icon: "FaPhone",
      },
    ],
  },
];

const Consultation = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    // Listen for window resize to detect mobile view changes
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="Contact-us-legal-consult-main-container"
      id="consultationContainer"
    >
      <div className="Contact-us-legal-consult-container">
        <h1 className="Contact-us-legal-consult-heading">Legal Consultation</h1>
        <p className="Contact-us-legal-consult-sub-heading">
          Contact us for dedicated, experienced and updated legal counsel in the
          various areas of the Indian judicial system and the laws that it
          upholds.
        </p>
      </div>
      <div className="course-tab-content-container">
        {!isMobileView && (
          <ul className="course-tab-list">
            {tabData.map((tab, index) => (
              <li
                key={index}
                className={`${
                  activeTab === index ? "course-active-tab" : ""
                } course-tab-list-item`}
                onClick={() => handleTabClick(index)}
              >
                {tab.title}
              </li>
            ))}
          </ul>
        )}
        <div className="course-main-container">
          {isMobileView ? (
            <div className="course-accordion-content-display">
              {tabData.map((tab, index) => (
                <div key={index} className="course-accordion">
                  <div
                    className={`course-accordion-header ${
                      activeTab === index ? "course-active-accordion" : ""
                    }`}
                    onClick={() => handleTabClick(index)}
                  >
                    {tab.title}
                  </div>
                  {activeTab === index && (
                    <div className="course-accordion-content">
                      <ul className="course-accordion-content-list-container">
                        {tab.content.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <div className="course-content-list-item-sub-container">
                              {item.icon &&
                                React.createElement(iconComponents[item.icon], {
                                  className: "course-content-list-icon",
                                })}
                              <div className="course-content-items-list">
                                <p className="course-content-heading">
                                  {item.key}
                                </p>
                                <p className="course-content-sub-heading">
                                  {item.key2}
                                </p>
                              </div>
                            </div>
                            <hr className="course-content-horizontal-line" />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="course-content-display">
              <ul>
                {tabData[activeTab].content.map((item, itemIndex) => (
                  <li key={itemIndex} className="course-Content-list-item">
                    <div className="course-content-list-item-sub-container">
                      {item.icon &&
                        React.createElement(iconComponents[item.icon], {
                          className: "course-content-list-icon",
                        })}
                      <div className="course-content-items-list">
                        <p className="course-content-heading">{item.key}</p>
                        <p className="course-content-sub-heading">
                          {item.key2}
                        </p>
                      </div>
                    </div>
                    <hr className="course-content-horizontal-line" />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Consultation;
