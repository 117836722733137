import React from "react";
import "./index.css";
import { AiFillFacebook, AiOutlineGlobal } from "react-icons/ai";
import { FaSquareXTwitter, FaSquareYoutube } from "react-icons/fa6";
import { FaGooglePlusSquare } from "react-icons/fa";
import { BsPhone } from "react-icons/bs";
import { BiRightArrow } from "react-icons/bi";
import { FaLocationArrow } from "react-icons/fa";
import { GrLinkedin } from "react-icons/gr";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import logoImage from "../../Images/logo.png";

const Footer = () => {
  return (
    <div className="footer-main-container">
      <div className="footer-desc-container">
        <img src={logoImage} alt="website logo" className="footer-logo" />
        <p className="footer-desc-conatiner-description">
          Syedan law Associates is founded by Rajender Khanna (Advocate) and
          President Guardians Human & Civil Rights Forum, an NGO with difference
          registered with Government of Telengana (Former AP) Conducting social
          activities for protection of Human Rights and Committed to Welfare of
          Public.
        </p>
        <div className="footer-logo-conatiner">
          <div className="footer-logo-section-container">
            <a href="https://www.facebook.com/" target="blank">
              <AiFillFacebook className="footer-logo-conatiner-logo" />
            </a>
          </div>
          <div className="footer-logo-section-container">
            <a href="https://www.facebook.com/" target="blank">
              <FaSquareXTwitter className="footer-logo-conatiner-logo" />
            </a>
          </div>
          <div className="footer-logo-section-container">
            <a href="https://www.facebook.com/" target="blank">
              <FaGooglePlusSquare className="footer-logo-conatiner-logo" />
            </a>
          </div>
          <div className="footer-logo-section-container">
            <a href="https://www.facebook.com/" target="blank">
              <FaSquareYoutube className="footer-logo-conatiner-logo" />
            </a>
          </div>
          <div className="footer-logo-section-container">
            <a href="https://www.facebook.com/" target="blank">
              <GrLinkedin className="footer-logo-conatiner-logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-quick-link-conatiner">
        <h2 className="footer-quick-link-container-heading">Quick Links</h2>
        <hr className="footer-quick-link-horizontal-line" />
        <div className="footer-conatiner-links-conatiner">
          <Link className="footer-quick-link-anchor" to="/aboutus">
            <BiRightArrow className="footer-arrow-logo" />
            About Us
          </Link>
          <Link className="footer-quick-link-anchor" to="/areaofpractice/civil">
            <BiRightArrow className="footer-arrow-logo" />
            Area of Practice
          </Link>
          <Link className="footer-quick-link-anchor" to="/contactus">
            <BiRightArrow className="footer-arrow-logo" />
            Contact
          </Link>
        </div>
      </div>
      <div className="footer-get-in-touch-container">
        <h2 className="footer-quick-link-container-heading">Get in Touch</h2>
        <hr className="footer-quick-link-horizontal-line" />
        <div className="footer-conatiner-get-in-touch-conatiner">
          <div className="footer-get-in-touch-links">
            <FaLocationArrow
              className="footer-get-in-touch-links-icons"
              style={{ fontSize: "45px" }}
            />
            <p className="footer-get-in-touch-links-text">
              # 203, MALLICK CHAMBERS, 2ND FLOOR, HYDERGUDA, OLD MLA QTRS ROAD,
              HYDERABAD - 500029
            </p>
          </div>
          <div className="footer-get-in-touch-links">
            <BsPhone className="footer-get-in-touch-links-icons" />
            <p className="footer-get-in-touch-links-text">(+91) 984-888-0884</p>
          </div>
          <div className="footer-get-in-touch-links">
            <MdEmail className="footer-get-in-touch-links-icons" />
            <p className="footer-get-in-touch-links-text">rk100in@yahoo.com</p>
          </div>
          <div className="footer-get-in-touch-links">
            <AiOutlineGlobal className="footer-get-in-touch-links-icons" />
            <p className="footer-get-in-touch-links-text">
              www.syedanlawfirm.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
