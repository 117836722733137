import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
import logoImage from "../../Images/logo.png";
import "./index.css";

const Header = () => {
  const [showNavItems, setShowNavItems] = useState(false);

  const toggleShowNavItems = () => {
    setShowNavItems(!showNavItems);
  };

  const renderNavItems = () => (
    <div className="nav-items-container">
      <ul className="nav-menu">
        <NavLink exact to="/" className="nav-link" activeClassName="active">
          <li className="nav-menu-item">HOME</li>
        </NavLink>
        <NavLink to="/aboutus" className="nav-link" activeClassName="active">
          <li className="nav-menu-item">ABOUT US</li>
        </NavLink>
        <NavLink
          to="/areaofpractice/civil"
          className="nav-link"
          activeClassName="active"
        >
          <li className="nav-menu-item">AREA OF PRACTICE</li>
        </NavLink>
        <NavLink to="/contactus" className="nav-link" activeClassName="active">
          <li className="nav-menu-item">CONTACT US</li>
        </NavLink>
      </ul>
    </div>
  );

  return (
    <nav className="nav-header">
      <div className="nav-content">
        <div className="nav-bar-mobile-logo-container">
          <NavLink to="/" className="nav-link">
            <img
              alt="website logo"
              className="nav-mobile-logo"
              src={logoImage}
            />
          </NavLink>
          {showNavItems ? (
            <button
              type="button"
              onClick={toggleShowNavItems}
              className="nav-close-button"
            >
              <AiFillCloseCircle />
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleShowNavItems}
              className="nav-mobile-btn"
            >
              <GiHamburgerMenu className="nav-mobile-menu-icon" />
            </button>
          )}
        </div>
        <div className="nav-bar-large-container">
          <NavLink to="/" className="nav-link">
            <img
              className="nav-website-logo"
              src={logoImage}
              alt="website logo"
            />
          </NavLink>
          <ul className="nav-menu">
            <NavLink exact to="/" className="nav-link">
              <li className="nav-menu-item">HOME</li>
            </NavLink>
            <NavLink to="/aboutus" className="nav-link">
              <li className="nav-menu-item">ABOUT US</li>
            </NavLink>
            <NavLink
              to="/areaofpractice/civil"
              className="nav-link"
              activeClassName="active"
            >
              <li className="nav-menu-item">AREA OF PRACTICE</li>
            </NavLink>
            <NavLink
              to="/contactus"
              className="nav-link"
              activeClassName="active"
            >
              <li className="nav-menu-item">CONTACT US</li>
            </NavLink>
          </ul>
        </div>
      </div>
      {showNavItems && renderNavItems()}
    </nav>
  );
};

export default Header;
