import React from "react";
import "./index.css";

function Articles() {
  return (
    <div className="article-law-container">
      <h1 className="article-law-container-heading">Latest Articles</h1>
      <div className="associate-article-card">
        <img
          className="law-blog-wp-content"
          src="https://khannalawassociates.in/blog/wp-content/uploads/2022/02/Notice-Under-41-A-1.jpeg"
          alt=""
        />
        <h4 className="latest-law">
          After Issuance of Notice Under 41-A CrPC Police Cannot Arrest Without
          Magistrate’s Permission, Rules HC- Know More
        </h4>
        <p className="latest-law">
          The Telangana High Court while considering a Pre-arrest bail
          application has issued a significant order on arrest of an accused
          after issuance of notice under Section 41-A of Cr.PC.
        </p>
        <button className="article-btn">Read More</button>
      </div>
    </div>
  );
}
export default Articles;
