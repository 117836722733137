import React from "react";
import Header from "../../components/NavBar";
import Footer from "../../components/Footer";
import { TiTickOutline } from "react-icons/ti";
import { CgNotes } from "react-icons/cg";
import { Link } from "react-router-dom";
import "./index.css";

const lawAssociates = [
  "Rajender Khanna- B.A LLB, LLM - PGD IPR, Cyber Law",
  "Nasreen Banu- B.Com LLB",
  "Yash Khanna- B.A LLB",
  "C. Santosh Kumar- B.Com LLB",
  "Kiran Medagiri- BE LLB, LLM - PGD Human Rights",
  "Sumalata- B.Com LLB",
  "Manish Sharma- B.Com LLB",
  "P Devender- MBA LLB, LLM",
];

const areaOfPractice = [
  {
    link: "civil",
    text: "Civil",
  },
  {
    link: "criminal",
    text: "Criminal",
  },
  {
    link: "cyberlaw",
    text: "Cyber Law",
  },
  {
    link: "matrimoniallaws",
    text: "Matrimonial Laws Of Divorcee, Dvc, Maintenance , Dowry",
  },
  {
    link: "humanrigits",
    text: "Human Rigits",
  },
];

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="about-us-banner-container">
        <h1 className="about-us-banner-container-heading">
          About - Syedan Law Associates
        </h1>
      </div>
      <div className="about-us-sub-main-container">
        <div className="about-us-law-associates-section">
          <p className="about-us-law-associates-section-heading">
            Syedan Law Associates partnership has the following distinguished
            legal experts in its ranks:
          </p>
          <div className="about-us-law-associates-section-conatiner">
            {lawAssociates.map((associate, index) => (
              <div
                key={index}
                className="about-us-law-associates-section-conatiner-element"
              >
                <TiTickOutline className="about-us-arrow-react-icon" />
                <p className="about-us-law-associates-section-conatiner-desc">
                  {associate}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="about-us-law-areaofpractice-section">
          <h3 className="about-us-law-areaofpractice-section-heading">
            Area of Practice
          </h3>
          <div className="about-us-law-areaofpractice-section-container">
            {areaOfPractice.map((area, index) => (
              <Link
                key={index}
                to={`/areaofpractice/${area.link}`}
                className="about-us-law-areaofpractice-section-link"
              >
                <CgNotes /> {area.text}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
