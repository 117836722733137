import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import "./index.css";

const contactList = [
  {
    id: 1,
    advocateNo: "ADVOCATE - 01",
    personName: "Mr. Rajender Khanna",
    personNo: "(+91) 966-661-0000",
  },
  {
    id: 1,
    advocateNo: "ADVOCATE - 02",
    personName: "Mrs. NASREEN BANU",
    personNo: "(+91) 984-888-0884",
  },
];

function ContactCard() {
  return (
    <div className="contact-cards-main-container">
      <div className="contact-first-card">
        <div className="contact-card-image-container">
          <img
            src="https://png.pngtree.com/png-vector/20220916/ourmid/pngtree-red-location-pin-icon-with-colored-folded-map-png-image_6177040.png"
            alt="contact"
            className="contact-card-first-image"
          />
        </div>
        <div className="abou-first-card-description">
          <h3 className="contact-card-heading">VISIT OUR OFFICE</h3>
          <p className="contact-first-card-para">
            C/O GUARDIANS HUMAN & CIVIL RIGHTS FORUM # 203, MALLICK CHAMBERS,
            2ND FLOOR, HYDERGUDA, OLD MLA QTRS ROAD, HYDERABAD - 500029
          </p>
        </div>
      </div>
      <div className="contact-first-card">
        <div className="contact-second-card-image-container">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzo_EhkY4T6W1oBt7fGnWvu7WkdfgF-gXfxg&usqp=CAU"
            alt="contact-second"
            className="contact-card-second-image"
          />
        </div>
        <h3 className="contact-card-heading">LET'S TALK</h3>
        <div className="contact-second-card-contact-container">
          {contactList.map((eachDetail) => (
            <div className="contact-second-card-contact">
              <div className="contact-second-card-detail-container">
                <div className="contact-second-card-details">
                  <h3 className="contact-second-card-details-heading">
                    {eachDetail.advocateNo}
                  </h3>
                  <p className="contact-first-card-para">
                    <FaUserTie className="contact-us-card-conatiner-react-icons" />
                    {eachDetail.personName}
                  </p>
                </div>
              </div>
              <div className="contact-second-card-detail-container">
                <div className="contact-second-card-details">
                  <h3 className="contact-second-card-details-heading">
                    Phone:
                  </h3>
                  <p className="contact-first-card-para">
                    <BsTelephoneFill className="contact-us-card-conatiner-react-icons" />
                    {eachDetail.personNo}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-first-card">
        <div className="contact-second-card-image-container">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuKTI0ibnEPVqv6_EoaTRy5PTHDTdbd8bAag&usqp=CAU"
            alt="third"
            className="contact-card-third-image"
          />
        </div>
        <h3 className="contact-card-heading">E-MAIL US</h3>
        <div className="contact-third-card-emails">
          <p className="contact-third-card-emails-name">rk100in@yahoo.com</p>
          <p className="contact-third-card-emails-name">prk100in@yahoo.com</p>
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
