import { React } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/NavBar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import "./index.css";

const areaOfPractice = [
  {
    link: "civil",
    text: "Civil",
    desc: "Civil cases are legal disputes between individuals, organizations, or entities that typically do not involve criminal charges. These cases revolve around issues of private rights and responsibilities, often seeking compensation or a remedy for harm suffered by one party due to the actions or negligence of another",
  },
  {
    link: "criminal",
    text: "Criminal",
    desc: "Criminal cases are legal proceedings that involve individuals or entities accused of committing a crime. These cases are a fundamental component of the justice system in most countries, serving to address and deter unlawful behavior, protect the rights of individuals, and maintain order in society",
  },
  {
    link: "cyberlaw",
    text: "Cyber Law",
    desc: "Cyber laws, also known as cybercrime laws or internet laws, are a critical component of the legal framework in the digital age. These laws are designed to govern and regulate various aspects of the online world, including activities, transactions, and behaviors that take place on the internet. They play a vital role in maintaining order, ensuring security, and protecting the rights and interests of individuals and organizations in the cyberspace",
  },
  {
    link: "matrimoniallaws",
    text: "Matrimonial Laws Of Divorcee, Dvc, Maintenance , Dowry",
    desc: "Matrimonial laws in many countries are designed to regulate various aspects of marriage, divorce, domestic violence, maintenance, and dowry. These laws aim to protect the rights and interests of individuals involved in marital relationships and address issues that may arise during and after marriage",
  },
  {
    link: "humanrigits",
    text: "Human Rigits",
    desc: "Human rights are a fundamental and universal concept that forms the cornerstone of modern society. They are the inherent rights and freedoms to which all individuals are entitled simply because they are human beings, irrespective of their nationality, race, gender, religion, or any other distinguishing characteristic. Human rights encompass a wide range of principles and protections that are essential for the dignity, equality, and well-being of every person on Earth.",
  },
];

const AreaOfPractice = () => {
  const { link } = useParams(); // Use the correct import statement
  const selectedArea = areaOfPractice.find((area) => area.link === link);

  return (
    <>
      <Header />
      <div className="area-ofpractice-banner-container">
        <h1 className="area-ofpractice-banner-container-heading">
          Area of Practice - Civil Law
        </h1>
      </div>
      <div className="area-of-practice-main-container">
        <div className="area-of-practice-sub-conatiner">
          <h3 className="about-us-law-areaofpractice-section-heading">
            Area of Practice
          </h3>
          <div className="area-of-practice-sub-conatiner-links-conatiner">
            {areaOfPractice.map((area, index) => (
              <Link
                key={index}
                to={`/areaofpractice/${area.link}`}
                className="about-us-law-area-of-practice-section-link"
              >
                <CgNotes /> {area.text}
              </Link>
            ))}
          </div>
        </div>

        <div className="area-of-practice-section-full-desc-container">
          <h1 className="area-of-practice-section-full-desc-container-heading">
            {selectedArea.text}
          </h1>
          <p className="area-of-practice-section-full-desc-container-desc">
            {selectedArea.desc}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AreaOfPractice;
