import React from "react";
import ContactCard from "../../components/Cards";
import Consultation from "../../components/Consultation/index.js";
import Header from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./index.css";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="contact-us-banner-container">
        <h1 className="contact-us-banner-container-heading">
          Contact - Syedan Law Associates
        </h1>
      </div>
      <ContactCard />
      <Consultation />
      <Footer />
    </>
  );
};

export default ContactUs;
