import React from "react";
import Header from "../../components/NavBar";
import Footer from "../../components/Footer";
import Area from "../../components/Area";
import Consultation from "../../components/Consultation";
import "./index.css";

const Home = () => {
  return (
    <>
      <Header />
      <Area />
      <Consultation />
      <Footer />
    </>
  );
};

export default Home;
